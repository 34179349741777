import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax styles
import router from './router' // 引入路由
import axios from 'axios';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.prototype.$axios = axios;
Vue.prototype.$apiBaseUrl = 'https://zy.dytv.cc';
Vue.prototype.$playerBaseUrl = 'https://player.heimuer.tv/index.html?url=';

Vue.use(Vuesax)
Vue.use(Vuetify)

Vue.config.productionTip = false

new Vue({
  router, // 使用路由
  vuetify: new Vuetify(),
  render: h => h(App),
}).$mount('#app')
