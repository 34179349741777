<template>
  <div class="list">
    <div v-if="isLoading" class="loading"></div>
    <div v-else class="results">
      <div v-if="searchQuery && movies.length > 0" class="search-info">
        <span class="tag">搜索: "{{ searchQuery }}"</span>
        <span class="tag">搜索结果数量: {{ movies.length }}</span>
      </div>
      <vs-row justify="center">
        <vs-card
            v-for="(movie) in movies"
            :key="movie.vod_id"
            class="movie-card"
            @click="playMovie(movie)"
        >
          <template #img>
            <img
                :src="movie.vod_pic"
                alt="Movie Poster"
                class="img"
                @error="setDefaultImage($event)"
            />
          </template>
          <template #title>
            <div
                :class="['movie-title', { scroll: movie.vod_name.length > 7 }]"
            >
              <h6>{{ movie.vod_name }}</h6>
            </div>
          </template>
          <template #text>
            <div
                :class="['movie-remarks', { scroll: movie.vod_remarks.length > 10 }]"
            >
              <p>{{ movie.vod_remarks }}</p>
            </div>
          </template>
          <template #interactions>
            <vs-button class="btn-chat" gradient @click="playMovie(movie)">
              <i class="bx bxl-play-store"></i>
              <span class="span">立即播放</span>
            </vs-button>
          </template>
        </vs-card>
      </vs-row>
      <div v-if="!isLoading && movies.length === 0" class="no-data">
        <i class="bx bx-info-circle"></i>
        <p>暂无数据</p>
      </div>
    </div>
    <!-- 悬浮按钮 -->
    <div
        id="floating-button"
        @mousedown="startDrag"
        @touchstart="startDrag"
        @click="goHome"
    >
      <i class="bx bx-home"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchQuery: '',
      movies: [],
      isLoading: false, // 新增的加载状态变量
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      buttonStartX: 0,
      buttonStartY: 0,
    };
  },
  methods: {
    updateQuery() {
      this.searchQuery = this.$route.query.q || '';
    },
    playMovie(movie) {
      this.$router.push({ name: 'PlayerPage', params: { movieId: movie.vod_id } });
    },
    async searchMovies() {
      this.isLoading = true; // 开始加载
      let loading = this.$vs.loading({
        type: 'circles',
      });
      try {
        const response = await this.$axios.get(
            `${this.$apiBaseUrl}/cjapi/mc/vod/json.html`,
            {
              params: {
                ac: 'list',
                wd: this.searchQuery,
              },
            }
        );
        this.movies = response.data.list;
      } catch (error) {
        console.error('Error fetching movies:', error);
      } finally {
        this.isLoading = false; // 加载完成
        loading.close();
      }
    },
    setDefaultImage(event) {
      event.target.src = 'https://via.placeholder.com/150';
    },
    goHome() {
      this.$router.push('/');
    },
    startDrag(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX || event.touches[0].clientX;
      this.dragStartY = event.clientY || event.touches[0].clientY;
      const button = document.getElementById('floating-button');
      this.buttonStartX = button.offsetLeft;
      this.buttonStartY = button.offsetTop;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    },
    onDrag(event) {
      if (!this.isDragging) return;
      const clientX = event.clientX || event.touches[0].clientX;
      const clientY = event.clientY || event.touches[0].clientY;
      const deltaX = clientX - this.dragStartX;
      const deltaY = clientY - this.dragStartY;
      const button = document.getElementById('floating-button');
      button.style.left = `${this.buttonStartX + deltaX}px`;
      button.style.top = `${this.buttonStartY + deltaY}px`;
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchmove', this.onDrag);
      document.removeEventListener('touchend', this.stopDrag);
    },
  },
  watch: {
    '$route.query.q': 'searchMovies',
  },
  mounted() {
    this.updateQuery();
    this.searchMovies();
  },
};
</script>
<style scoped>
@import 'boxicons/css/boxicons.min.css';

.list {
  text-align: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /*background: rgba(255, 255, 255, 0.8);*/
  border-radius: 10px;
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
}

.search-box {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-container input {
  width: 300px;
  padding: 10px 30px 10px 10px; /* 给图标留出空间 */
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
}

.search-icon {
  position: absolute;
  right: 10px;
  font-size: 20px;
  color: #999;
  cursor: pointer;
}

.results {
  margin-top: 20px;
  text-align: center;
}

.results h2 {
  margin-bottom: 20px;
}

.movie-card {
  margin: 10px;
  display: inline-block;
  width: 160px;
  text-align: center;
}

.movie-card img {
  width: 160px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.movie-title,
.movie-remarks {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.movie-title.scroll h6,
.movie-remarks.scroll p {
  display: inline-block;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-100% + 100px)); /* 假设容器宽度为100px */
  }
  60% {
    transform: translateX(calc(-100% + 100px)); /* 假设容器宽度为100px */
  }
  100% {
    transform: translateX(0);
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* 可以根据需要调整 */
}

.no-data {
  margin-top: 20px;
  font-size: 18px;
  color: #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
}

.no-data i {
  font-size: 50px;
  color: #999;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.search-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  background: linear-gradient(to right, #5f97ff, #e04122);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /*border-radius: 20px;*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid;
  border-image: linear-gradient(to right, #5f97ff, #e04122) 1;
}

#floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  user-select: none;
}

#floating-button i {
  font-size: 24px;
}

@media (max-width: 768px) {
  .list {
    padding: 7px;
  }

  .movie-card {
    width: 160px;
  }

  .movie-card img {
    width: 160px;
    height: 200px;
  }
}
</style>
