<template>
  <div class="player-page">
    <div class="current-info">
      <span class="tag">当前播放：{{ currentMovieName }}</span>
      <span class="tag">{{ currentEpisodeName }}</span>
    </div>
    <div v-if="routes.length > 1" class="route-tabs">
      <vs-button
          v-for="(route, index) in routes"
          :key="index"
          :class="['route-button', { active: index === selectedRoute }]"
          @click="selectRoute(index)"
          type="transparent"
      >
        {{ route }}
      </vs-button>
    </div>
    <div class="route-hint" v-if="routes.length > 1">
      如果当前播放卡顿或剧集不全可尝试点击上方更换线路
    </div>
    <div class="player-container">
      <iframe :src="playerUrl" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="controls" v-if="episodes.length > 1">
      <vs-button
          v-for="episode in episodes"
          :key="episode.id"
          :class="['episode-button', { active: episode.id === selectedEpisode }]"
          @click="selectEpisode(episode.id)"
          type="transparent"
      >
        {{ episode.name }}
      </vs-button>
    </div>
    <div class="bottom-controls">
      <vs-button-group>
        <vs-button v-if="episodes.length > 1" @click="playPrevious" flat icon>
          <i class='bx bx-skip-previous'></i>
        </vs-button>
        <vs-button v-if="episodes.length > 1" @click="playNext" flat icon>
          <i class='bx bx-skip-next'></i>
        </vs-button>
        <vs-button @click="returnHome" flat icon>
          <i class='bx bx-home'></i>
        </vs-button>
        <vs-button @click="goBack" flat icon>
          <i class='bx bx-arrow-back'></i>
        </vs-button>
      </vs-button-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedEpisode: null,
      episodes: [],
      routes: [],
      selectedRoute: 0,
      currentMovieName: '',
    };
  },
  computed: {
    playerUrl() {
      const selectedEpisode = this.episodes.find(
          (episode) => episode.id === this.selectedEpisode
      );
      return selectedEpisode
          ? this.$playerBaseUrl + selectedEpisode.url
          : '';
    },
    currentEpisodeName() {
      const selectedEpisode = this.episodes.find(
          (episode) => episode.id === this.selectedEpisode
      );
      return selectedEpisode
          ? `当前集数: ${selectedEpisode.name}`
          : '未选择集数';
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    returnHome() {
      this.$router.push('/');
    },
    playPrevious() {
      const currentIndex = this.episodes.findIndex(
          (episode) => episode.id === this.selectedEpisode
      );
      if (currentIndex > 0) {
        this.selectedEpisode = this.episodes[currentIndex - 1].id;
      }
    },
    playNext() {
      const currentIndex = this.episodes.findIndex(
          (episode) => episode.id === this.selectedEpisode
      );
      if (currentIndex < this.episodes.length - 1) {
        this.selectedEpisode = this.episodes[currentIndex + 1].id;
      }
    },
    selectEpisode(id) {
      this.selectedEpisode = id;
    },
    selectRoute(index) {
      this.selectedRoute = index;
      this.loadEpisodes();
    },
    fetchEpisodes() {
      const movieId = this.$route.params.movieId;
      fetch(
          `${this.$apiBaseUrl}/cjapi/mc/vod/json.html?ac=detail&ids=${movieId}`
      )
          .then((response) => response.json())
          .then((data) => {
            this.currentMovieName = data.list[0].vod_name;
            let playFrom = data.list[0].vod_play_from.split('$$$');
            let playUrls = data.list[0].vod_play_url.split('$$$');

            // 过滤掉不包含 .m3u8 的线路和对应的播放地址
            playFrom = playFrom.filter((route, index) => {
              const urls = playUrls[index].split('#');
              return urls.some((url) => url.includes('.m3u8'));
            });

            playUrls = playUrls.filter((url) => {
              const urls = url.split('#');
              return urls.some((url) => url.includes('.m3u8'));
            });

            this.routes = playFrom;
            this.playUrls = playUrls;
            this.loadEpisodes();
            this.updateRouteWithMovieName();
          })
          .catch((error) => {
            console.error('Error fetching episodes:', error);
          });
    },
    loadEpisodes() {
      const playUrls = this.playUrls[this.selectedRoute].split('#');
      const episodes = playUrls
          .map((url, index) => {
            const [name, videoUrl] = url.split('$');
            return {
              id: index + 1,
              name,
              url: videoUrl,
            };
          })
          .filter((episode) => episode.url.endsWith('.m3u8')); // 过滤掉不以 .m3u8 结尾的地址
      this.episodes = episodes;
      this.selectedEpisode = episodes[0]?.id || null; // 默认选择第一集
    },
    updateRouteWithMovieName() {
      // 使用编程式导航更新路由
      this.$router.replace({
        name: 'PlayerPage',
        params: { movieId: this.$route.params.movieId },
        query: { movieName: this.currentMovieName },
      });
    },
  },
  created() {
    this.fetchEpisodes();
    this.$router.push({
      name: 'PlayerPage',
      query: { movieName: this.currentMovieName },
    });
  },
};
</script>

<style scoped>
.player-page {
  text-align: center;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  background: linear-gradient(
      to right,
      rgba(135, 206, 250, 0.4),
      rgba(255, 182, 193, 0.4)
  );
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.route-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.route-button {
  font-size: 12px;
  height: 40px; /* 统一按钮高度 */
  background-color: transparent; /* 未选中时没有背景 */
  border: 1px solid #007bff; /* 添加边框以确保按钮可见 */
  margin: 5px; /* 按钮之间的间距 */
  box-sizing: border-box; /* 包括边框和内边距 */
}

.route-button.active {
  background-color: #007bff;
  color: white;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.route-hint {
  margin-bottom: 20px;
  font-size: 14px;
  background: linear-gradient(to right, #5f97ff, #e04122);
  -webkit-background-clip: text;
  color: transparent;
  animation: gradient-animation 10s ease infinite;
}

.current-info {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tag {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  background: linear-gradient(to right, #5f97ff, #e04122);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /*border-radius: 20px;*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid;
  border-image: linear-gradient(to right, #5f97ff, #e04122) 1;
}

.player-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  margin-bottom: 20px;
}

.player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin-bottom: 20px; /* 确保集数按钮在底部控制按钮之上 */
}

.episode-button {
  font-size: 12px;
  flex: 1 0 18%; /* 统一按钮宽度，确保一排显示五个 */
  height: 40px; /* 统一按钮高度 */
  background-color: transparent; /* 未选中时没有背景 */
  border: 1px solid #007bff; /* 添加边框以确保按钮可见 */
  margin: 5px; /* 按钮之间的间距 */
  box-sizing: border-box; /* 包括边框和内边距 */
  max-width: 100px; /* 添加最大宽度，防止按钮过长 */
}

@media (max-width: 768px) {
  .episode-button {
    padding: 5px 10px;
    font-size: 12px;
    width: auto;
    max-width: 100px; /* 添加最大宽度，防止按钮过长 */
  }

  .controls {
    justify-content: center;
    gap: 5px;
  }
}

.episode-button.active {
  background-color: #007bff; /* 选中时背景色 */
  color: white;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.bottom-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center; /* 居中对齐 */
  z-index: 1000;
  margin: 0 auto; /* 横向居中 */
}



.control-button {
  width: auto;
  height: 40px; /* 统一按钮高度 */
  margin: 0 10px; /* 按钮之间的间距 */
}
</style>
