<template>
  <div class="home">
    <div class="logo-container">
      <img src="/logo.png" alt="Logo" class="logo">
    </div>
    <vs-row class="search-box" justify="center">
      <div class="search-input-container">
        <input type="text" v-model="searchQuery" placeholder="殿下，想看什么？" @keyup.enter="searchMovies"/>
        <i class="bx bx-search search-icon" @click="searchMovies"></i>
      </div>
    </vs-row>
    <div class="today-updates">
      <div v-if="isLoading" class="loading"></div>
      <div class="header">
        <h2 class="animated-gradient">今日更新</h2>
      </div>
      <vs-row justify="center">
        <vs-card v-for="(movie) in filteredMovies" :key="movie.vod_id" class="movie-card" @click="playMovie(movie)">
          <template #img>
            <img :src="movie.vod_pic" alt="Movie Poster" class="img" @error="setDefaultImage($event)">
          </template>
          <template #title>
            <div class="movie-title-container">
              <div :class="['movie-title', { 'scroll': movie.vod_name.length > 7 }]">
                <h6>{{ movie.vod_name }}</h6>
              </div>
            </div>
          </template>
          <template #text>
            <div class="movie-remarks-container">
              <div :class="['movie-remarks', { 'scroll': movie.vod_remarks.length > 10 }]">
                <p>{{ movie.vod_remarks ? movie.vod_remarks : "欢迎观看" }}</p>
              </div>
            </div>
          </template>
          <template #interactions>
            <vs-button class="btn-chat" gradient>
              <i class='bx bxl-play-store'></i>
            </vs-button>
          </template>
        </vs-card>
      </vs-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchQuery: '',
      todayUpdates: [],
      isLoading: false,
    };
  },
  computed: {
    filteredMovies() {
      return this.todayUpdates.slice(0, 6);
    },
  },
  created() {
    this.searchToday();
  },
  methods: {
    async searchToday() {
      this.isLoading = true; // 开始加载
      let loading = this.$vs.loading({
        type: 'circles'
      });
      try {
        const response = await this.$axios.get(`${this.$apiBaseUrl}/cjapi/mc/vod/json.html`, {
          params: {
            ac: 'list'
          },
        });
        this.todayUpdates = response.data.list;
      } catch (error) {
        console.error('Error fetching movies:', error);
      } finally {
        this.isLoading = false; // 加载完成
        loading.close();
      }
    },
    setDefaultImage(event) {
      event.target.src = 'https://via.placeholder.com/150';
    },
    searchMovies() {
      this.$router.push({name: 'ListPage', query: {q: this.searchQuery}});
    },
    playMovie(movie) {
      this.$router.push({ name: 'PlayerPage', params: { movieId: movie.vod_id }});
    },
  },
};
</script>
<style scoped>
@import 'boxicons/css/boxicons.min.css';

.home {
  text-align: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 0px;
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.logo-container {
  margin-bottom: 0px;
}

.logo {
  width: 300px;
  height: auto;
}

.search-box {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-container input {
  width: 300px;
  padding: 10px 40px 10px 20px; /* 给图标留出空间 */
  border: none;
  border-radius: 25px;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background: linear-gradient(45deg, #fbc2eb, #a6c1ee);
  color: #333;
}

.search-input-container input::placeholder {
  color: rgba(51, 51, 51, 0.7);
}

.search-input-container input:focus {
  outline: none;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #a6c1ee, #fbc2eb);
}

.search-icon {
  position: absolute;
  right: 15px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.search-icon:hover {
  color: #a6c1ee;
}

.movie-title-container, .movie-remarks-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.movie-title, .movie-remarks {
  display: inline-block;
  white-space: nowrap;
  position: relative;
}

.movie-title.scroll h6, .movie-remarks.scroll p {
  display: inline-block;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-100% + 100px)); /* 假设容器宽度为100px */
  }
  60% {
    transform: translateX(calc(-100% + 100px)); /* 假设容器宽度为100px */
  }
  100% {
    transform: translateX(0);
  }
}

.today-updates {
  margin-top: 0px;
  text-align: center;
}

.today-updates h2 {
  margin-bottom: 20px;
}

.movie-card {
  margin: 7px;
  display: inline-block;
  width: 160px;
  text-align: center;
  cursor: pointer; /* 增加鼠标悬停效果 */
}

.movie-card img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.header h2 {
  font-size: 24px;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  background: linear-gradient(90deg, #fbc2eb, #a6c1ee, #fbc2eb);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s ease infinite;
}

@media (max-width: 768px) {
  .home {
    padding: 7px;
    display: flex;
    flex-direction: column;
  }

  .search-box {
    flex-direction: column;
  }

  .movie-card {
    width: 160px;
  }
}
</style>
