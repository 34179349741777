// src/router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import ListPage from '../views/ListPage.vue'
import PlayerPage from '../views/PlayerPage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/list',
        name: 'ListPage',
        component: ListPage,
    },
    {
        path: '/player/:movieId',
        name: 'PlayerPage',
        component: PlayerPage,
        props: route => ({ movieName: route.query.name })
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 添加 beforeEach 导航守卫
router.beforeEach((to, from, next) => {
    // 默认标题
    let title = to.meta.title || '屁屁搜|影视搜索|全网影视免费在线播放'
    // console.log(666)
    // 如果路由有 meta 并且设置了 title，则更新 document.title
    if (to.name === 'ListPage' && to.query.q) {
        title = `${to.query.q} - ${title}`;
    } else if (to.params.movieId && to.name === 'PlayerPage' && to.query.movieName) {
        // 假设你从服务器或其他地方获取电影标题
        // 这里只是一个示例，实际应用中可能需要异步获取数据
        title = `${to.query.movieName}在线播放 - ${title}`;
    }

    document.title = title;
    next();
});

export default router;
